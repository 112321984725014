import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import animation_seo from "../../assets/lottie/bodymovin-seo.json";
import animation_ppc from "../../assets/lottie/bodymovin-ppc.json";
import animation_cro from "../../assets/lottie/bodymovin-cro.json";
import animation_copy from "../../assets/lottie/bodymovin-copy.json";
import animation_content from "../../assets/lottie/bodymovin-content.json";

import animation_seo_moreservices from "../../assets/lottie/bodymovin-seo--white.json";
import animation_ppc_moreservices from "../../assets/lottie/bodymovin-ppc--white.json";
import animation_cro_moreservices from "../../assets/lottie/bodymovin-cro--white.json";
import animation_copy_moreservices from "../../assets/lottie/bodymovin-copy--white.json";
import animation_content_moreservices from "../../assets/lottie/bodymovin-content--white.json";

import LottieInner from './LottieInner';

const Wrapper = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;

    video,
    svg{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    } 

    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media only screen and (max-width: 1250px) {
        justify-content: center;
      }
      
    }
`;

const OuterWrapper = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
`;

const LottieWrap = (data) => {

  // Set if hover effect is not required
  const noHoverEffect = data.noHoverEffect
  const delay = data.delay || 100
  const delayAnimation = data.delayAnimation
  const allowLoop = data.allowLoop;
  const playOnload = data.playOnload  
  const forcePlay = data.forcePlay;
  const useParentHoverController = data.useParentHoverController;
  
  const animationContainer = useRef();
  
  const [animationData, setAnimationData] = useState(null)
  const [isHovering, setIsHovering] = useState(false)
  const [hasHovered, setHasHovered] = useState(false)
  const [isInViewPort, setIsInViewPort] = useState(null)
  const [initialAnimationComplete, setInitialAnimationComplete] = useState(null)
  

  useEffect(() => {
    let anim;
    if(data.animation === 'seo'){
      setAnimationData(animation_seo)
    }
    
    if(data.animation === 'cro'){
      setAnimationData(animation_cro)
    }

    if(data.animation === 'ppc'){
      setAnimationData(animation_ppc)
    }
    
    if(data.animation === 'copy'){
      setAnimationData(animation_copy)
    }
    
    if(data.animation === 'content'){
      setAnimationData(animation_content)
    }
    
    if(data.animation === 'seo_moreservices'){
      setAnimationData(animation_seo_moreservices)
    }
    
    if(data.animation === 'cro_moreservices'){
      setAnimationData(animation_cro_moreservices)
    }

    if(data.animation === 'ppc_moreservices'){
      setAnimationData(animation_ppc_moreservices)
    }
    
    if(data.animation === 'copy_moreservices'){
      setAnimationData(animation_copy_moreservices)
    }
    
    if(data.animation === 'content_moreservices'){
      setAnimationData(animation_content_moreservices)
    }
    
    if(typeof window !== 'undefined' && useParentHoverController !== true){
      animationContainer.current.closest('.lottie-wrapper--outer').addEventListener('mouseenter', hoveringStateFunc, {passive: true})
      animationContainer.current.closest('.lottie-wrapper--outer').addEventListener('mouseleave', hoveringStateFunc, {passive: true})
      animationContainer.current.closest('.lottie-wrapper--outer').addEventListener('touchstart', hoveringStateFunc, {passive: true})
      animationContainer.current.closest('.lottie-wrapper--outer').addEventListener('touchend', hoveringStateFunc, {passive: true})
    }
    if(typeof window !== 'undefined' && useParentHoverController === true){
      const containerButtons = animationContainer.current.closest('li').querySelectorAll('a.link')
      containerButtons.forEach(button => {
        button.addEventListener('mouseenter', hoveringStateFunc, {passive: true})
        button.addEventListener('mouseleave', hoveringStateFunc, {passive: true})
        button.addEventListener('touchstart', hoveringStateFunc, {passive: true})
        button.addEventListener('touchend', hoveringStateFunc, {passive: true})
      })
    }

  }, [])
  
  


  function inviewOnChange (isVisible) {
    setIsInViewPort(true)
    // console.log('in view')
  }

  

  const hoveringStateFunc = (e, target) => {
    
    if(!noHoverEffect ){
      if(e.type === 'mouseenter' || e.type === 'touchstart'){
        setIsHovering(true)
        setHasHovered(true)
        if(e.target.closest('article')){
          e.target.closest('article').classList.add('js_hover')
        }
        if(e.target.closest('li')){
          e.target.closest('li').classList.add('js_hover')
        }
      }
      if(e.type === 'mouseleave' || e.type === 'touchend'){
        setIsHovering(false)
        if(e.target.closest('article')){
          e.target.closest('article').classList.remove('js_hover')
        }
        if(e.target.closest('li')){
          e.target.closest('li').classList.remove('js_hover')
        }
      }
    }
  }

  return (
          
        <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInViewPort}  onChange={inviewOnChange}>
             
        {({isVisible}) =>
            <OuterWrapper 
            className="lottie-wrapper--outer"
            // onMouseEnter={hoveringStateFunc}
            // onMouseLeave={hoveringStateFunc}
            >  
              <Wrapper className="lottie-wrapper--inner" 
                data-target={data.animation} 
                ref={animationContainer}
                >
           
              {
                animationData !== null && animationData !== undefined ? 
                  
                    <LottieInner                   
                      animation={animationData}
                      inViewPort={isInViewPort || forcePlay}
                      hovering={isHovering}
                      hasHovered={hasHovered}
                      isArrow={data.animation === 'arrow' ? true : false}
                      allowLoop={allowLoop}
                      initialAnimationComplete={initialAnimationComplete}
                      setInitialAnimationComplete={setInitialAnimationComplete}
                      delay={delay}
                      playOnload={playOnload}
                    />
                    
                    : ''
              }
                
              </Wrapper>
            </OuterWrapper>

          }
        </VisibilitySensor>
  );
};

export default LottieWrap;