import React, {useRef, useState, useEffect} from 'react'
import Lottie from "lottie-react";

const LottieInner = (data) => {
  const lottieRef = useRef();

  const [hasPlayed, setHasPlayed] = useState(false);
  
  useEffect(() => {
    if(data.playOnload && hasPlayed === false){
      setTimeout(function(){
        lottieRef.current.playSegments([0, 90], true) 
        setHasPlayed(true);
      }.bind(this),data.delay) 
    }
  }, [lottieRef.current])


  useEffect(() => {
  // Initial Inview Animation
  if(data.inViewPort === true && lottieRef.current !== undefined && data.initialAnimationComplete === null) {
    data.setInitialAnimationComplete(false)  
    if(data.isArrow === true){
      lottieRef.current.playSegments([0, 85], true) 
    }else{
      lottieRef.current.playSegments([0, 90], true) 
    }
  }

  },[lottieRef.current])

  // Mouse Over Animation
  if(data.hovering === true && lottieRef.current !== undefined && data.initialAnimationComplete === true && data.hasHovered === true && data.noHoverEffect === undefined) {
    lottieRef.current.setDirection(1);
    lottieRef.current.playSegments([95, 120], true)
  }
  // Mouse Out Animation
  if(data.hovering === false && lottieRef.current !== undefined && data.initialAnimationComplete === true  && data.hasHovered === true && data.noHoverEffect === undefined) {
    lottieRef.current.setDirection(-1);
    lottieRef.current.play();
  }
  
  return (
    <Lottie 
      animationData={data.animation} 
      loop={false}
      autoplay={false}
      lottieRef={lottieRef}
      onEnterFrame={
        (currentFrame) => { 
          if(currentFrame.currentTime >= (currentFrame.totalTime - 2)){
            data.setInitialAnimationComplete(true)
          }
        }
      }

    />

  )
};
export default LottieInner;